import { OptionsClass, RaiseStatus, RaiseType } from './interfaces';
import { BiddingEntityType } from './types';

export enum BiddingEntityStep {
  ATTACHMENTS = 'attachments',
  AUTHORISED_PERSONS = 'authorised-persons',
  BASIC_INFORMATION = 'basic-information',
  BROKERAGE_DETAILS = 'brokerage-details',
  OWNERS = 'owners',
  SETTLEMENT_CONTACTS = 'settlement-contacts',
  SUMMARY = 'summary',
}

export enum CompanySection {
  ANNOUNCEMENTS = 'section-announcements',
  FEATURED_IN = 'section-featured-in',
  FINANCIALS = 'section-financials',
  KEY_EXECUTIVES = 'section-key-executives',
  MARKET_DATA = 'section-market-data',
  NEWS = 'section-news',
  RAISE_HISTORY = 'section-raise-history',
  TOP_SHAREHOLDERS = 'section-top-shareholders',
  SECTOR_PEERS = 'section-sector-peers',
}

export enum COOKIE {
  NEWSFEED_FILTER = '_fresh_newsfeed_filter',
  SOCKET_TOKEN = '_fresh_socket_token',
}

export enum FeatureFeedback {
  notification_v2 = 'notification_v2',
}

export enum ShareholderVerificationStep {
  EXPERIENCED_INVESTOR_DECLARATION = 'experienced-investor-declaration',
  VERIFY_SHAREHOLDER_STATUS = 'verify-shareholder-status',
  CONFIRM_EMAIL = 'confirm-email',
  CONFIRM_MOBILE = 'confirm-mobile',
  IDENTITY_CHECK = 'identity-check',
}

export enum StorageKey {
  IDENTITY_VERIFICATION = 'identity-verification',
  HIDE_ONBOARDING_PENDING_BAR = 'hide-onboarding-pending',
  HIDE_ONBOARDING_SUCCESS_BAR = 'hide-onboarding-success',
  IDENTITY_DATA = 'identity-data',
  ONBOARDING_DATA = 'onboarding-data',
}

export enum VerifyEntityStep {
  WELCOME = 'welcome',
  IV = 'IV',
  COMPLETE = 'complete',
}

// TODO: double check bidding entity works with new enum definition
export const BIDDING_ENTITY_TYPE_NAMES: {
  name: string;
  type: BiddingEntityType;
}[] = [
  {
    name: 'Individual',
    type: BiddingEntityType.individual,
  },
  {
    name: 'Partnership',
    type: BiddingEntityType.partnership,
  },
  {
    name: 'Company',
    type: BiddingEntityType.company,
  },
  {
    name: 'Trust',
    type: BiddingEntityType.trust,
  },
  {
    name: 'Self Managed Super Fund',
    type: BiddingEntityType.smsf,
  },
];

export const DEFAULT_ERROR_MESSAGE = 'Oops! Something went wrong.';

export const EMPTY_SLATE_EDITOR =
  '{"object":"value","document":{"object":"document","data":{},"nodes":[{"object":"block","type":"paragraph","data":{},"nodes":[{"object":"text","text":"","marks":[]}]}]}}';

export const IP_BLACKLIST = [
  '3.106.15.144',
  '3.104.229.67',
  '52.65.213.84',
  '13.211.19.246',
  '3.25.53.57',
  '13.210.174.22',
];

export const IPO_DATA: {
  attachments: {
    name: string;
    url: string;
  }[];
  bannerUrl?: string | null;
  closedAt: string;
  ipoId: number;
  issuePrice: number;
  logoUrl?: string | null;
  marketCap: number;
  maxAmount?: number | null;
  minAmount?: number | null;
  name: string;
  offerAnnouncedAt: string;
  offerHashId: string;
  options?: string | null;
  optionsAvailable: boolean;
  optionsClass: OptionsClass | null;
  optionsRatioDenominator: number | null;
  optionsRatioNumerator: number | null;
  outcomeAnnouncedAt: string;
  proposedListingDate?: string | null;
  sector: string;
  settlementDate?: string | null;
  status: RaiseStatus;
  termSheet: string;
  ticker: string;
  type: RaiseType;
} = {
  attachments: [
    {
      name: 'Prospectus',
      url: 'https://storage.googleapis.com/downloads.freshequities.com/Microba%20IPO%20Pathfinder%20Prospectus%20Feb22.pdf',
    },
  ],
  bannerUrl:
    'https://storage.googleapis.com/downloads.freshequities.com/Microba%20Life%20Sciences%20Limited%20Banner.jpg',
  closedAt: '2022-02-09T23:00:00.312Z',
  ipoId: 2,
  issuePrice: 0.45,
  logoUrl:
    'https://storage.googleapis.com/downloads.freshequities.com/Microba%20Logo.jpg',
  marketCap: 123500000,
  maxAmount: 30000000,
  minAmount: 30000000,
  name: 'Microba Life Sciences Limited',
  offerHashId: 'abc123',
  // options: '+ 1:1 unlisted options ($0.888, 36 months expiry)',
  options: '',
  // optionsAvailable: true,
  // optionsClass: 'unlisted',
  // optionsRatioDenominator: 1,
  // optionsRatioNumerator: 1,
  optionsAvailable: false,
  optionsClass: null,
  optionsRatioDenominator: null,
  optionsRatioNumerator: null,
  offerAnnouncedAt: '2022-02-09T00:00:00.312Z',
  outcomeAnnouncedAt: '2022-03-28T11:00:00.312Z',
  proposedListingDate: '2022-04-05T11:00:00.312Z',
  sector: 'Biotech',
  settlementDate: '2022-03-28T11:00:00.312Z',
  status: 'open' as RaiseStatus,
  termSheet:
    'https://storage.googleapis.com/downloads.freshequities.com/Microba%20IPO%20-%20Term%20Sheet.PDF',
  ticker: 'ASX:MAP',
  type: 'ipo' as RaiseType,
};

export const IS_MAINTENANCE = false;

export const NUMERAL_FORMAT_NUMBER = '0,0[.][0000]';
export const NUMERAL_FORMAT_PERCENT = '0.00%';
export const NUMERAL_FORMAT_PRICE = '$0,0[.][0000]';
export const NUMERAL_FORMAT_MONEY = '$0,0[.][00]';
export const NUMERAL_FORMAT_PRICE_MARKET = '$0,0.00[00]';

export const OFFICE_NUMBER = '+61396610441';
export const OFFICE_NUMBER_FORMATTED = '(03) 9661 0441';

export const SALUTATIONS = [
  { name: 'Mr', value: 'Mr' },
  { name: 'Mrs', value: 'Mrs' },
  { name: 'Ms', value: 'Ms' },
  { name: 'Dr', value: 'Dr' },
  { name: 'Title *', value: '' },
];

// Got a few of these from here, but there's a lot of silly ones: https://www.gimme-shelter.com/steet-types-designations-abbreviations-50006/
export const STREET_TYPES = [
  'Abbey',
  'Alley',
  'Approach',
  'Arcade',
  'Avenue',
  'Bend',
  'Bluff',
  'Bottom',
  'Boulevard',
  'Brow',
  'Bypass',
  'Causeway',
  'Circuit',
  'Circus',
  'Close',
  'Copse',
  'Corner',
  'Cove',
  'Court',
  'Crescent',
  'Crossing',
  'Cul-de-sac',
  'Drive',
  'End',
  'Esplanade',
  'Expressway',
  'Falls',
  'Farm',
  'Ferry',
  'Flat',
  'Forest',
  'Freeway',
  'Frontage',
  'Gardens',
  'Glade',
  'Glen',
  'Green',
  'Grove',
  'Heights',
  'Highway',
  'Junction',
  'Keep',
  'Landing',
  'Lane',
  'Link',
  'Loop',
  'Mall',
  'Mews',
  'Mission',
  'Motorway',
  'Mount',
  'Neck',
  'Overpass',
  'Packet',
  'Parade',
  'Park',
  'Parkway',
  'Pike',
  'Place',
  'Plaza',
  'Promenade',
  'Quay',
  'Reserve',
  'Ranch',
  'Range',
  'Ridge',
  'Rise',
  'Road',
  'Row',
  'Square',
  'Street',
  'Strip',
  'Tarn',
  'Terrace',
  'Thoroughfare',
  'Track',
  'Trunkway',
  'Turnpike',
  'Underpass',
  'View',
  'Vista',
  'Walk',
  'Way',
  'Walkway',
  'Yard',
];

export const VALIDATION_PHONE = /^[+]? ?(\([0-9]{1,4}\)|[0-9]{1,4})?[ 0-9]*$/;
