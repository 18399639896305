import { gql, useQuery } from '@apollo/client';

export interface S708Certificate {
  id: string;

  accountantFirm: string | null;
  accountantName: string | null;
  accountantEmail: string | null;
  accountantPhone: string | null;
  expiresAt: string | null;
  verified: boolean;
  type: 'professional' | 'retail' | 'sophisticated';

  certificate: string | null;
}

export interface Me {
  id: string;
  insertedAt: string;

  confirmedAt: string | null;
  confirmationSentAt: string | null;
  displayName: string | null;
  email: string;
  failedAttempts: number;
  firstName: string | null;
  lastName: string | null;
  lockedAt: string | null;
  mobileNumber: string | null;
  mobileNumberConfirmedAt: string | null;
  preferredName: string | null;

  avatar: string | null;

  digitalId: {
    id: string;
  } | null;

  expandedRole: {
    id: string;

    expiredAt: string | null;
    name: string;
  }[];

  frankieCheck: {
    id: string;

    checkDate: string;
    verified: boolean;
  } | null;

  fromEmailSignup: boolean;

  hasBiddingEntities: boolean;

  invalidated: boolean;

  investorType:
    | 'experienced'
    | 'professional'
    | 'retail'
    | 'sophisticated'
    | 'unknown';

  isAdmin: boolean;

  isApproved: boolean;

  isAppUser: boolean;

  isBanned: boolean;

  isConfirmed: boolean;

  isRetail: boolean;

  isSophisticated: boolean;

  isSuspended: boolean;

  isVerified: boolean;

  offerLetterSignature: string | null;

  onboardingStatus: {
    key:
      | 'auto_s708'
      | 'email_confirm'
      | 'iv_check'
      | 'mobile_confirm'
      | 's708'
      | 'stat_dec';
    status: 'accepted' | 'expired' | 'not_exist' | 'pending' | 'rejected';
  }[];

  s708Certificate: S708Certificate | null;

  subscription: {
    status: 'Active' | 'Not Subscribed';
  };

  token: string;

  totalConfirmedBids: number;
}

export interface MeQueryData {
  me: Me | null;
}

const MeQuery = gql`
  query MeQuery {
    me {
      id
      insertedAt
      confirmedAt
      confirmationSentAt
      displayName
      email
      failedAttempts
      firstName
      lastName
      lockedAt
      mobileNumber
      mobileNumberConfirmedAt
      preferredName
      avatar
      digitalId {
        id
      }
      expandedRole {
        id
        expiredAt
        name
      }
      frankieCheck {
        id
        checkDate
        verified
      }
      fromEmailSignup
      hasBiddingEntities
      invalidated
      investorType
      isAdmin
      isApproved
      isAppUser
      isBanned
      isConfirmed
      isRetail
      isSophisticated
      isSuspended
      isVerified
      offerLetterSignature
      onboardingStatus {
        key
        status
      }
      s708Certificate {
        id
        accountantFirm
        accountantName
        accountantEmail
        accountantPhone
        expiresAt
        verified
        type
        certificate
      }
      subscription {
        status
      }
      token
      totalConfirmedBids
    }
  }
`;

export function useMeQuery() {
  return useQuery<MeQueryData>(MeQuery);
}

export default MeQuery;
