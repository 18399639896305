import React from 'react';
// Utils
import { ICountry } from '../utils-ts/interfaces';

export interface ICountryContext {
  countries: ICountry[];
  loading: boolean;
}

const CountryContext = React.createContext<ICountryContext>({
  countries: [],
  loading: true,
});

export default CountryContext;
