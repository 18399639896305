import React, { FC } from 'react';
import Head from 'next/head';

interface JsonLdProps {
  data: object;
  keyId: string;
}

export const JsonLd: FC<JsonLdProps> = ({ data, keyId }) => (
  <Head>
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(data, null, ' '),
      }}
      key={keyId}
    />
  </Head>
);
