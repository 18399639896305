import React from 'react';
// Modules
import { ApolloProvider } from '@apollo/client';
import posthog from 'posthog-js';
import DayJSUtils from '@date-io/dayjs';
import { AppProps } from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
// MUI Core
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
// MUI Pickers
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// Components
import AnalyticsProvider from '../components-ts/analytics/AnalyticsProvider';
import Modal from '../components-ts/common/Modal';
import DefaultSEO from '../components-ts/seo/DefaultSEO';
// Contexts
import ContextProvider from '../contexts/ContextProvider';
// FUI
import Tooltip from '../fui/Tooltip';
// Utils
// import { logPage } from '../utils-ts/analytics';
import { useApollo } from '../utils-ts/client';
import { scrollToTop } from '../utils-ts/scroll-helper';
import theme from '../utils-ts/theme';
// Styles
import '../styles/main.css';

interface MyAppProps extends AppProps {
  err?:
    | (Error & {
        statusCode?: number | undefined;
      })
    | null;
}

const MyApp: React.FC<MyAppProps> = ({ Component, err, pageProps }) => {
  const apolloClient = useApollo(pageProps.initialApolloState);

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    if (process.env.NODE_ENV === 'production') {
      posthog.init(`${process.env.NEXT_PUBLIC_POSTHOG_API_KEY}`, {
        api_host: 'https://app.posthog.com',
      });
    }

    Router.events.on('routeChangeStart', () => NProgress.start());

    Router.events.on('routeChangeComplete', (url) => {
      // logPage(url);

      NProgress.done();

      scrollToTop();
    });

    Router.events.on('routeChangeError', () => NProgress.done());
  }, []);

  return (
    <>
      <DefaultSEO />
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DayJSUtils}>
            <AnalyticsProvider>
              <ContextProvider>
                <CssBaseline />
                <Component {...pageProps} err={err} />
                <Modal />
                <Tooltip />
              </ContextProvider>
            </AnalyticsProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ApolloProvider>
    </>
  );
};

export default MyApp;
