import React, {
  createContext,
  FC,
  useContext,
  useState,
  ReactNode,
} from 'react';

interface ITooltipContext {
  content: ReactNode;
  hideTooltip: () => void;
  open: boolean;
  showTooltip: (title: string, content: ReactNode) => void;
  title: string;
}

const TooltipContext = createContext<ITooltipContext>({
  content: null,
  hideTooltip: () => {},
  open: false,
  showTooltip: () => {},
  title: '',
});

export const TooltipContextProvider: FC = ({ children }) => {
  const [content, setContent] = useState<ReactNode>(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');

  const hideTooltip = () => {
    setContent(null);
    setOpen(false);
    setTitle('');
  };

  const showTooltip = (newTitle: string, newContent: ReactNode) => {
    setContent(newContent);
    setOpen(true);
    setTitle(newTitle);
  };

  return (
    <TooltipContext.Provider
      value={{
        content,
        hideTooltip,
        open,
        showTooltip,
        title,
      }}
    >
      {children}
    </TooltipContext.Provider>
  );
};

export const useTooltip = () => useContext(TooltipContext);
