import { createMuiTheme } from '@material-ui/core/styles';

export enum Color {
  ASH = '#777777',
  BLACK = '#000000',
  BLUE_BRIGHT = '#3396FF',
  BLUE_MIDNIGHT = '#213F45',
  BLUE_SURFACE = '#C2DAF4',
  CRITICAL = '#C71A1A',
  CRITICAL_PRESSED = '#460000',
  CRITICAL_SURFACE = '#FCF4F4',
  CRITICAL_TEXT = '#7F0000',
  EGG_SHELL = '#FBF5EF',
  EGG_SHELL_DARKER = '#E9E2DC',
  EGG_SHELL_TEXT = '#473D32',
  FRESH = '#00A370',
  GREY = '#F5F5F5',
  GREY_SUBDUED = '#FAFAFA',
  JET = '#333333',
  LAVENDER = '#E9E3ED',
  LAVENDER_DARKER = '#D8D1DD',
  LAVENDER_TEXT = '#3C3542',
  MONSTERA = '#058A5A',
  MOSS = '#0B5A47',
  NORI = '#0E3F38',
  PALE_BLUE = '#E4EBEF',
  PALE_BLUE_DARKER = '#D4DEE3',
  PALE_BLUE_DARKEST = '#4C6973',
  PALE_BLUE_TEXT = '#364954',
  PEACH = '#F9E4D9',
  PEACH_DARKER = '#E9D1C5',
  PEACH_TEXT = '#614E44',
  PINE = '#096B51',
  PINK = '#FBEFF0',
  PINK_DARKER = '#EDDDDE',
  PINK_TEXT = '#46383A',
  PREMIUM = '#112428',
  SAFARI_PINNED_TAB = '#5BBAD5',
  SAGE = '#E8EDE8',
  SAGE_DARKER = '#D0D9D0',
  SAGE_TEXT = '#0E3F38',
  SILVER = '#DDDDDD',
  SMOKE = '#AAAAAA',
  STONE = '#555555',
  TURQUOISE = '#E6F6EF',
  TURQUOISE_DARKER = '#CFE7DD',
  TURQUOISE_TEXT = '#354235',
  WARNING = '#CC7100',
  WARNING_SURFACE = '#F9F0E5',
  WARNING_TEXT = '#A35A00',
  WHITE = '#FFFFFF',
}

export enum Font {
  SANS_SERIF = "'Inter', sans-serif",
  SERIF = "'orpheuspro', serif",
}

export enum Shadow {
  DEPTH_00 = '0px 1px 1px rgba(0, 0, 0, 0.05), 0px 0px 8px rgba(0, 0, 0, 0.05)',
  DEPTH_01 = '0px 0px 1px rgba(0, 0, 0, 0.1), 0px 2px 16px rgba(0, 0, 0, 0.08)',
  DEPTH_02 = '0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 16px rgba(0, 0, 0, 0.1)',
  DEPTH_03 = '0px 0px 1px rgba(0, 0, 0, 0.2), 0px 8px 24px rgba(0, 0, 0, 0.05)',
  DEPTH_04 = '0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 32px rgba(0, 0, 0, 0.15)',
}

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: 'none !important',
        '&.Mui-disabled': {
          backgroundColor: Color.SILVER,
          color: Color.SMOKE,
        },
      },
      containedPrimary: {
        '&:active': {
          backgroundColor: Color.PALE_BLUE_DARKEST,
        },
        '&:hover': {
          backgroundColor: Color.PALE_BLUE_TEXT,
        },
      },
      containedSecondary: {
        '&:active': {
          backgroundColor: Color.NORI,
        },
        '&:hover': {
          backgroundColor: Color.PINE,
        },
      },
      containedSizeSmall: {
        fontSize: 14,
        padding: '11px 12px',
      },
      iconSizeSmall: {
        fontSize: 16,
        '& > :first-child': {
          fontSize: 16,
        },
      },
      iconSizeMedium: {
        fontSize: 19,
        '& > :first-child': {
          fontSize: 19,
        },
      },
      outlined: {
        backgroundColor: Color.WHITE,
        padding: '11px 16px 12px 16px',
        '&.Mui-disabled': {
          border: `1px solid ${Color.SILVER}`,
        },
      },
      outlinedPrimary: {
        '&:active': {
          backgroundColor: Color.PALE_BLUE_DARKER,
        },
        '&:hover': {
          backgroundColor: Color.PALE_BLUE,
        },
      },
      outlinedSecondary: {
        border: `1px solid ${Color.PINE}`,
        color: Color.PINE,
        '&:active': {
          backgroundColor: Color.SAGE_DARKER,
        },
        '&:hover': {
          backgroundColor: Color.SAGE,
          border: `1px solid ${Color.PINE}`,
        },
        '&.Mui-disabled': {
          border: `1px solid ${Color.SILVER}`,
        },
      },
      outlinedSizeSmall: {
        fontSize: 14,
        padding: '10px 12px',
      },
      root: {
        borderRadius: 0,
        fontFamily: Font.SANS_SERIF,
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: 1.2,
        padding: '12px 16px 13px 16px',
        textTransform: 'none',
        '&.Mui-disabled': {
          color: Color.SMOKE,
        },
      },
      text: {
        padding: 0,
      },
      textPrimary: {
        '&:active': {
          backgroundColor: Color.PALE_BLUE,
          textDecoration: 'underline',
        },
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
        },
      },
      textSecondary: {
        color: Color.PINE,
        '&:active': {
          backgroundColor: Color.SAGE,
          textDecoration: 'underline',
        },
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
        },
      },
      textSizeSmall: {
        fontSize: 14,
        padding: 0,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: `1px solid ${Color.SILVER}`,
      },
    },
  },
  palette: {
    background: {
      default: Color.WHITE,
      paper: Color.WHITE,
    },
    divider: Color.SILVER,
    error: {
      main: Color.CRITICAL,
    },
    primary: {
      main: Color.PREMIUM,
    },
    secondary: {
      dark: Color.PINE,
      main: Color.FRESH,
    },
    success: {
      main: Color.FRESH,
    },
    text: {
      disabled: Color.SMOKE,
      hint: Color.SMOKE,
      primary: Color.JET,
      secondary: Color.ASH,
    },
    warning: {
      main: Color.WARNING,
    },
  },
  typography: {
    fontFamily: Font.SANS_SERIF,
    fontWeightBold: 600,
    fontWeightLight: 300,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
  },
});

export default theme;
