import { createContext } from 'react';

interface IModalAction {
  color?: 'primary' | 'secondary';
  onClick?: () => void;
  title: string;
}

export interface IModalContext {
  actions: IModalAction[];
  close: () => void;
  content?: JSX.Element;
  hideClose: boolean;
  isVisible: boolean;
  open: (options: IModalOptions) => void;
  title: string;
}

interface IModalOptions {
  actions?: IModalAction[];
  content?: JSX.Element;
  hideClose?: boolean;
  onClose?: () => void;
  title: string;
}

const ModalContext = createContext<IModalContext>({
  actions: [],
  close: () => {},
  content: undefined,
  hideClose: false,
  isVisible: false,
  open: () => {},
  title: '',
});

export default ModalContext;
