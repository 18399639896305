/* This file is deprecated. Please use routes.ts instead. */

import { BiddingEntityStep } from './constants';
import { landingPageSlug, LandingPageType } from './contentful/landingPages';

export const asPath = {
  announcement: (announcementId: string) => `/announcements/${announcementId}`,
  asxSectorsIndex: (sector: string) => `/ASX/sectors/${sector}`,
  asxSubIndustriesIndex: (sector: string, subIndustry: string) =>
    `/ASX/sectors/${sector}/${subIndustry}`,
  authLogIn: (next?: string) =>
    next ? `/auth/login?next=${next}` : '/auth/login',
  author: (slug: string) => `/resource-centre/author/${slug}`,
  authResetPassword: (token: string, initialSet: string) =>
    `/auth/reset-password?token=${token}&initial_set=${initialSet}`,
  authSignUp: (next?: string) =>
    next ? `/auth/signup?next=${next}` : '/auth/signup',
  bid: (
    exchangeKey: string,
    listingKey: string,
    offerId: string,
    step: string,
  ) => `/${exchangeKey}/${listingKey}/${offerId}/bid/${step}`,
  biddingEntity: (biddingEntityId: string) =>
    `/bidding-entity/${biddingEntityId}`,
  biddingEntityCreate: (biddingEntityId: string, step: BiddingEntityStep) =>
    `/bidding-entity/new/${biddingEntityId}/${step}`,
  biddingEntityEdit: (biddingEntityId: string) =>
    `/bidding-entity/${biddingEntityId}?edit=true`,
  biddingEntityVerifyOwner: (token: string | string[]) =>
    `/verify-entity/${token}`,
  bidV2: (bidId: string, exchangeKey: string, listingKey: string) =>
    `/${exchangeKey}/${listingKey}/bid/${bidId}`,
  capitalRaises: (loggedIn: boolean) =>
    loggedIn ? '/live-capital-raises' : '/capital-raises',
  companyComparison: (exchangeKey: string, ticker1: string, ticker2: string) =>
    `/${exchangeKey}/compare/${ticker1}-vs-${ticker2}`,
  identityVerification: (step: string) => `/identity-verification/${step}`,
  indexProfile: (code: string) => `/asx-indices/${code}`,
  landingPage: (pageType: LandingPageType, slug: string) =>
    `/resources/${landingPageSlug(pageType)}/${slug}`,
  landingPageIndex: (pageType: LandingPageType) =>
    `/resources/${landingPageSlug(pageType)}`,
  offer: (exchangeKey: string, listingKey: string) =>
    `/${exchangeKey}/${listingKey}/offer`,
  offerLetterAcceptance: (letterId: string, multi?: boolean) =>
    multi
      ? `/offer-letter-acceptance/${letterId}?multi=true`
      : `/offer-letter-acceptance/${letterId}`,
  offerLetterPage: (offerLetterId: string) => `/offer-letter/${offerLetterId}`,
  offerLetterExternal: (token: string) => `/offer-letter/external/${token}`,
  organisation: (exchangeKey: string, listingKey: string, source?: 'offer') =>
    source
      ? `/${exchangeKey}/${listingKey}?utm_source=${source}`
      : `/${exchangeKey}/${listingKey}`,
  organisationCapitalRaises: (exchangeKey: string, listingKey: string) =>
    `/${exchangeKey}/${listingKey}/capital-raises`,
  outcome: (exchangeKey: string, listingKey: string, offerId: string) =>
    `/${exchangeKey}/${listingKey}/offer/${offerId}/outcome`,
  post: (slug: string) => `/resource-centre/${slug}`,
  tag: (tag: string) => `/resource-centre/tag/${tag}`,
  topic: (slug: string) => `/resource-centre/topic/${slug}`,
  welcome: (token: string) => `/welcome/${token}`,
};

export const href = {
  // about: '/about',
  announcement: '/announcements/[announcementId]',
  asx: '/ASX',
  asxDelisted: '/ASX/delisted',
  asxSectors: '/ASX/sectors',
  asxSectorsIndex: '/ASX/sectors/[sector]',
  asxSubIndustriesIndex: '/ASX/sectors/[sector]/[subIndustry]',
  authForgotPassword: '/auth/forgot-password',
  authResetPassword: '/auth/reset-password',
  authLogIn: '/auth/login',
  authOnboarding: '/auth/onboarding/[step]',
  author: '/resource-centre/author/[slug]',
  authSignUp: '/auth/signup',
  bid: '/[exchangeKey]/[listingKey]/[offerId]/bid/[step]',
  biddingEntity: '/bidding-entity/[biddingEntityId]',
  biddingEntityCreate: '/bidding-entity/new',
  biddingEntityCreateAndUpdate: '/bidding-entity/new/[biddingEntityId]/[step]',
  biddingEntityVerifyOwner: '/verify-entity/[token]',
  biddingEntities: '/bidding-entities',
  bidV2: '/[exchangeKey]/[listingKey]/bid/[bidId]',
  capitalRaises: '/capital-raises',
  comingSoon: (featureName: string, next: string) =>
    `/coming-soon?featureName=${featureName}&next=${next}`,
  companyComparison: '/[exchangeKey]/compare/[comparisonKeys]',
  contactUs: '/contact-us',
  evtac: '/electronic-verification-terms-and-conditions',
  emailConfirmed: '/email-confirmed',
  faq: '/faq',
  home: '/',
  identityVerification: '/identity-verification/[step]',
  indexProfile: '/asx-indices/[code]',
  indices: '/asx-indices',
  landingPage: '/resources/[pageType]/[slug]',
  landingPageIndex: '/resources/[pageType]',
  liveCapitalRaises: '/live-capital-raises',
  markets: '/markets',
  notFound: '/not-found',
  notification: '/notification',
  offer: '/[exchangeKey]/[listingKey]/offer',
  offerLetterPage: '/offer-letter/[offerLetterId]',
  onboarding: '/onboarding',
  organisation: '/[exchangeKey]/[listingKey]',
  organisationCapitalRaises: '/[exchangeKey]/[listingKey]/capital-raises',
  outcome: '/[exchangeKey]/[listingKey]/offer/[offerId]/outcome',
  pastCapitalRaises: '/past-capital-raises',
  post: '/resource-centre/[slug]',
  privacyPolicy: '/privacy-policy',
  // TODO: Clean up profile routes.
  profile: '/account',
  profileConfirmMobileNumber: '/account/confirm-mobile-number',
  profileSetMobileNumber: '/account/set-mobile-number',
  profileDashboard: '/account/dashboard',
  profileNotifications: '/account/notifications',
  profilePersonalInformation: '/account/personal-information',
  profileSecurity: '/account/security',
  profileTurnOnDealNotification: '/account/turn-on-deal-notification',
  resourceCentre: '/resource-centre',
  resourceCentreSearch: '/resource-centre/search',
  termsAndConditions: '/terms-and-conditions',
  tag: '/resource-centre/tag/[tag]',
  topic: '/resource-centre/topic/[slug]',
  verifyIdentity: '/verify-identity',
  verifyInvestorQualification: '/verify-investor-qualification',
  welcome: '/welcome/[token]',
};
