import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  // denyUrls: [/chrome/, /extensions/, /pdfjs/, /posthog/, /segment/],
  denyUrls: [/chrome/, /extensions/, /pdfjs/, /posthog/],
  dsn: SENTRY_DSN,
  enabled: process.env.NODE_ENV === 'production',
  ignoreErrors: [
    "Can't find variable: DragEvent",
    'Expected identifier',
    'Failed to fetch',
    'Illegal invocation',
    'Value is null',
    /Failed to register a ServiceWorker/,
    /Non-Error promise rejection/,
    /pdfjs/,
  ],
});
