import React, { FC } from 'react';
// MUI Core
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// MUI Icons
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
// Contexts
import { useTooltip } from '../contexts/TooltipContext';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  content: {
    marginLeft: 40,
  },
  paper: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
}));

interface TooltipProps {}

const Tooltip: FC<TooltipProps> = () => {
  const classes = useStyles();

  const { content, hideTooltip, open, title } = useTooltip();

  return (
    <Drawer
      anchor="bottom"
      classes={{ paper: classes.paper }}
      onClose={hideTooltip}
      open={open}
    >
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={2} wrap="nowrap">
              <Grid item style={{ display: 'flex' }}>
                <InfoIcon color="action" />
              </Grid>
              <Grid item xs>
                <Typography variant="h6">{title}</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={hideTooltip}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.content}>{content}</div>
          </Grid>
        </Grid>
      </div>
    </Drawer>
  );
};

export default Tooltip;
