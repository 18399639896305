import React from 'react';
// Modules
import clsx from 'clsx';
import Link from 'next/link';

export interface Props {
  children?: React.ReactNode;
  className?: string;
  color?: 'danger' | 'primary' | 'secondary' | 'warning';
  disabled?: boolean;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  id?: string;
  // This will be passed to Next.js <Link /> component.
  internalHref?: string;
  noWrap?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  size?: 'small' | 'medium';
  startIcon?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'text' | 'outlined' | 'contained';
  target?: '_blank';
}

const Button = React.forwardRef<HTMLButtonElement, Props>(function Button(
  props,
  ref,
) {
  const {
    children,
    className,
    color = 'primary',
    disabled,
    endIcon,
    fullWidth,
    id,
    internalHref,
    onClick,
    size = 'medium',
    startIcon,
    type = 'button',
    variant = 'contained',
    target,
  } = props;

  function renderButton() {
    return (
      <button
        className={clsx(
          'inline-flex items-center space-x-3 font-medium leading-[1.2] whitespace-nowrap border',
          !className?.includes('justify') && 'justify-center',
          fullWidth && 'w-full',
          size === 'small' && 'text-sm',
          size === 'medium' && 'text-base',
          color === 'danger' &&
            variant === 'contained' &&
            'text-white bg-critical hover:bg-critical-text active:bg-critical-pressed border-transparent',
          color === 'warning' &&
            variant === 'contained' &&
            'text-critical hover:text-white active:text-white bg-critical-surface hover:bg-critical border-transparent',
          color === 'primary' &&
            variant === 'contained' &&
            'text-white bg-premium hover:bg-pale-blue-text active:bg-pale-blue-darkest border-transparent',
          color === 'secondary' &&
            variant === 'contained' &&
            'text-white bg-fresh hover:bg-pine active:bg-nori border-transparent',
          variant === 'contained' &&
            'disabled:text-smoke disabled:bg-silver disabled:cursor-not-allowed',
          color === 'primary' &&
            variant === 'outlined' &&
            'text-premium hover:bg-pale-blue active:bg-pale-blue-darker border-premium',
          color === 'secondary' &&
            variant === 'outlined' &&
            'text-pine hover:bg-sage active:bg-sage-darker border-pine',
          variant === 'outlined' &&
            'disabled:text-smoke bg-white disabled:bg-white disabled:border-smoke disabled:cursor-not-allowed',
          color === 'primary' &&
            variant === 'text' &&
            'text-premium hover:underline active:bg-pale-blue-darker border-transparent',
          color === 'secondary' &&
            variant === 'text' &&
            'text-pine hover:underline active:bg-sage-darker border-transparent',
          variant === 'text' && 'p-0',
          variant !== 'text' && size === 'small' && 'py-[11px] px-4',
          variant !== 'text' && size === 'medium' && 'px-4 pt-[11px] pb-3',
          className,
        )}
        disabled={disabled}
        id={id}
        onClick={onClick}
        ref={ref}
        type={type}
      >
        {startIcon && (
          <span className="flex justify-center items-center">{startIcon}</span>
        )}
        <span>{children}</span>
        {endIcon && (
          <span className="flex justify-center items-center">{endIcon}</span>
        )}
      </button>
    );
  }

  if (internalHref) {
    return (
      <Link href={internalHref}>
        <a target={target}>{renderButton()}</a>
      </Link>
    );
  }

  return renderButton();
});

export default Button;
