import { useEffect } from 'react';
// Modules
import * as Sentry from '@sentry/nextjs';
import posthog from 'posthog-js';
import cookie from 'cookie';
import dayjs from 'dayjs';
// GraphQL
import { useMeQuery } from '../../graphql-ts/queries/MeQuery.graphql';
// Utils
import { COOKIE } from '../../utils-ts/constants';
import { formatRolesForAnalytics, getPhoneNumber } from '../../utils-ts/helper';

const AnalyticsProvider: React.ComponentType = ({ children }) => {
  const { data } = useMeQuery();

  useEffect(() => {
    if (data?.me) {
      const { me } = data;

      document.cookie = cookie.serialize(COOKIE.SOCKET_TOKEN, me.token, {
        maxAge: 60 * 60 * 24 * 365,
        path: '/',
      });

      const joinedCohort = dayjs(me.insertedAt).format('YYYY,MM');
      const joinedMonth = dayjs(me.insertedAt).format('MM');
      const joinedYear = dayjs(me.insertedAt).format('YYYY');

      if (process.env.NODE_ENV === 'production') {
        posthog.identify(me.id, {
          email: me.email,
          mobileNumber: me.mobileNumber ? getPhoneNumber(me.mobileNumber) : '-',
          name:
            me.firstName && me.lastName
              ? `${me.firstName} ${me.lastName}`
              : '-',
          isAdmin: me.isAdmin,
          isApproved: me.isApproved,
          isConfirmed: me.isConfirmed,
          isSophisticated: me.isSophisticated,
          isSuspended: me.isBanned,
          isVerified: me.isVerified,
          joinedCohort,
          joinedMonth,
          joinedYear,
          roles: formatRolesForAnalytics(me.expandedRole),
        });
      }

      // window.analytics?.ready(() => {
      //   window.analytics?.identify(me.id, {
      //     activated: me.totalConfirmedBids > 0 ? 'Yes' : 'No',
      //     admin: me.isAdmin ? 'Yes' : 'No',
      //     banned: me.isBanned ? 'Yes' : 'No',
      //     confirmed: me.isConfirmed ? 'Yes' : 'No',
      //     email: me.email,
      //     joinedCohort,
      //     joinedMonth,
      //     joinedYear,
      //     name:
      //       me.firstName && me.lastName
      //         ? `${me.firstName} ${me.lastName}`
      //         : '-',
      //     platform: 'web',
      //     qualified: me.isSophisticated ? 'Yes' : 'No',
      //     roles: formatRolesForAnalytics(me.expandedRole),
      //     verified: me.isVerified ? 'Yes' : 'No',
      //   });
      // });
    }
  }, [data]);

  useEffect(() => {
    if (data?.me) {
      Sentry.setUser({
        email: data.me.email,
        id: data.me.id,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [data]);

  return <>{children}</>;
};

export default AnalyticsProvider;
