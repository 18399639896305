import { createContext, useContext, useEffect, useState } from 'react';
// Modules
import { useRouter } from 'next/router';

interface IWebViewContext {
  isWebView: boolean;
}

const WebViewContext = createContext<IWebViewContext>({
  isWebView: false,
});

export const WebViewProvider: React.ComponentType = ({ children }) => {
  const [isWebView, setWebView] = useState(false);

  const router = useRouter();

  useEffect(() => {
    setWebView(router.query.webview === 'true');
  }, [router.query.webview]);

  return (
    <WebViewContext.Provider
      value={{
        isWebView,
      }}
    >
      {children}
    </WebViewContext.Provider>
  );
};

export function useWebView() {
  return useContext(WebViewContext);
}
