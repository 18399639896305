import React from 'react';
// Modules
import { isSupportedCountry, parsePhoneNumber } from 'libphonenumber-js';
import dayjs from 'dayjs';
import orderBy from 'lodash/orderBy';
import numeral from 'numeral';
import { Value } from 'slate';
import Plain from 'slate-plain-serializer';
// GraphQL
import { BiddingEntity } from '../graphql-ts/fragments/BiddingEntityFragment.graphql';
import { Listing } from '../graphql-ts/fragments/ListingFragment.graphql';
import { Me } from '../graphql-ts/queries/MeQuery.graphql';
import { OfferLetter } from '../graphql-ts/queries/SettlementOfferLetterV2Query.graphql';
// Utils
import { ICountry } from './interfaces';
import { href } from './pages';
import {
  CheckStatus,
  DebtCouponFrequency,
  DebtCouponType,
  OfferType,
} from './types';

export function abbreviateNumber(input: number) {
  const value = numeral(input).format('0,0[.][00]a');

  if (value.endsWith('k')) {
    return numeral(input).format('0,0');
  }

  if (value.endsWith('m')) {
    return value.replace('m', ' million');
  }

  if (value.endsWith('b')) {
    return value.replace('b', ' billion');
  }

  if (value.endsWith('t')) {
    return value.replace('t', ' trillion');
  }

  return value;
}

export function capitalise(text: string) {
  return text.length ? text.charAt(0).toUpperCase() + text.substring(1) : text;
}

export function countDecimals(value: number) {
  const str = `${value}`;
  const elements = str.split('.');

  if (elements.length === 1) {
    return 0;
  }

  return elements[1].length;
}

export function copyToClipboard(text: string) {
  // Previous version wasn't working always in chrome
  // https://stackoverflow.com/questions/47879184/
  const textarea = document.createElement('textarea');
  textarea.textContent = text;
  document.body.appendChild(textarea);
  textarea.select();
  const selection = document.getSelection();
  const range = document.createRange();
  range.selectNode(textarea);
  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand('copy');
    selection.removeAllRanges();
  }
  document.body.removeChild(textarea);
}

/**
 * Display formatted date.
 */
export function displayDate(date: Date, type: 'long' | 'short') {
  let monthString = '';
  switch (date.getMonth()) {
    case 0:
      monthString = 'Jan';
      break;
    case 1:
      monthString = 'Feb';
      break;
    case 2:
      monthString = 'Mar';
      break;
    case 3:
      monthString = 'Apr';
      break;
    case 4:
      monthString = 'May';
      break;
    case 5:
      monthString = 'Jun';
      break;
    case 6:
      monthString = 'Jul';
      break;
    case 7:
      monthString = 'Aug';
      break;
    case 8:
      monthString = 'Sep';
      break;
    case 9:
      monthString = 'Oct';
      break;
    case 10:
      monthString = 'Nov';
      break;
    case 11:
      monthString = 'Dec';
      break;
    default:
      monthString = '';
      break;
  }

  switch (type) {
    case 'short':
      return `${monthString} ${date.getDate()}, ${date.getFullYear()}`;
    default:
      return `${date.getDate()} ${monthString} ${date.getFullYear()}, ${formatToTwoDigits(
        date.getHours(),
      )}:${formatToTwoDigits(date.getMinutes())}:${formatToTwoDigits(
        date.getSeconds(),
      )}`;
  }
}

/**
 * Format a given date (ISO string) into a more readable format.
 *
 * @param date Date in ISO string.
 */
export function formatDate(date: string) {
  const currentdayjs = dayjs();
  const datedayjs = dayjs(date);

  if (currentdayjs.isSame(datedayjs, 'day')) {
    return datedayjs.format('[Today,] h:mmA');
  }

  if (currentdayjs.add(1, 'day').isSame(datedayjs, 'day')) {
    return datedayjs.format('[Tomorrow,] h:mmA');
  }

  if (currentdayjs.subtract(1, 'day').isSame(datedayjs, 'day')) {
    return datedayjs.format('[Yesterday,] h:mmA');
  }

  if (currentdayjs.isSame(datedayjs, 'month')) {
    return datedayjs.format('dddd D MMM, h:mmA');
  }

  if (currentdayjs.isSame(datedayjs, 'year')) {
    return datedayjs.format('D MMMM');
  }

  return datedayjs.format('D MMMM YYYY');
}

export function formatNumber(value: number, prefix?: string) {
  if (Math.abs(value) / 1000000000 >= 1) {
    return `${value < 0 ? '-' : ''}${prefix || ''}${
      Math.floor(Math.abs(value) / 10000000) / 100
    }b`;
  }

  if (Math.abs(value) / 1000000 >= 1) {
    return `${value < 0 ? '-' : ''}${prefix || ''}${
      Math.floor(Math.abs(value) / 10000) / 100
    }m`;
  }

  if (Math.abs(value) / 1000 >= 1) {
    return `${value < 0 ? '-' : ''}${prefix || ''}${
      Math.floor(Math.abs(value) / 10) / 100
    }k`;
  }

  return `${value < 0 ? '-' : ''}${prefix || ''}${Math.abs(value)}`;
}

export function formatNumberWithoutDecimals(value: number, prefix?: string) {
  if (Math.abs(value) / 1000000000 >= 1) {
    return `${value < 0 ? '-' : ''}${prefix || ''}${Math.floor(
      Math.floor(Math.abs(value) / 10000000) / 100,
    )}b`;
  }

  if (Math.abs(value) / 1000000 >= 1) {
    return `${value < 0 ? '-' : ''}${prefix || ''}${Math.floor(
      Math.floor(Math.abs(value) / 10000) / 100,
    )}m`;
  }

  if (Math.abs(value) / 1000 >= 1) {
    return `${value < 0 ? '-' : ''}${prefix || ''}${Math.floor(
      Math.floor(Math.abs(value) / 10) / 100,
    )}k`;
  }

  return `${value < 0 ? '-' : ''}${prefix || ''}${Math.abs(value)}`;
}

export function formatRolesForAnalytics(roles: { name: string }[]) {
  const result = orderBy(roles, 'name', 'asc').reduce(
    (acc, role) => (acc === '' ? role.name : `${acc},${role.name}`),
    '',
  );

  return result;
}

/**
 * Format number to two digits.
 */
function formatToTwoDigits(input: number) {
  const inputString = `${input}`;

  if (inputString.length === 1) {
    return `0${inputString}`;
  }

  return inputString;
}

export function getBiddingEntityStatusText(biddingEntity: BiddingEntity) {
  if (biddingEntity.verified) {
    return 'Verified';
  }

  if (biddingEntity.status === 'draft') {
    return 'Draft';
  }

  return 'Pending Verification';
}

export function getCountryName(countries: ICountry[], search: string) {
  const country = countries.find(
    (c) =>
      c.alpha2Code === search || c.alpha3Code === search || c.name === search,
  );

  return country ? country.name : 'Unknown';
}

export function getCouponFrequency(frequency: DebtCouponFrequency | null) {
  switch (frequency) {
    case 'annual':
      return 'Annually';
    case 'quarterly':
      return 'Quarterly';
    case 'monthly':
      return 'Monthly';
    case 'semi-annual':
      return 'Semi-annually';
    default:
      return '-';
  }
}

export function getCouponType(type: DebtCouponType | null) {
  switch (type) {
    case 'fixed':
      return 'Fixed';
    case 'floating':
      return 'Floating';
    case 'payable at maturity':
      return 'Payable at Maturity';
    default:
      return '-';
  }
}

export function getErrorMessage(error: any) {
  if (error) {
    if (error.graphQLErrors) {
      const message = error.graphQLErrors.reduce(
        (prev: any, curr: any, i: number) => {
          if (i === 0) {
            return curr.message;
          }

          return `${prev}, ${curr.message}`;
        },
        '',
      );

      return message || 'Oops! Something went wrong.';
    }

    if (error.networkError) {
      return 'Sorry, there was an error on our server. Please try again later.';
    }

    return error.message ?? 'Oops! Something went wrong.';
  }

  return 'Oops! Something went wrong.';
}

export function getFormattedShareholderStatus(rawValue: string | null) {
  const shareholderStatusMap = new Map([
    ['new', 'New shareholder'],
    ['past', 'Past shareholder'],
    ['existing', 'Existing shareholder'],
  ]);

  if (rawValue) {
    const value = shareholderStatusMap.get(rawValue);

    if (value) {
      return value;
    }
  }

  return '-';
}

export function getFullName(
  firstName?: string | null,
  lastName?: string | null,
  fallback?: string | null,
) {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  return fallback || 'Unknown';
}

export function getOfferLetterFileName(offerLetter: OfferLetter) {
  const nameElements = [
    offerLetter.content.issuer,
    'Offer Letter',
    offerLetter.content.type,
    offerLetter.content.biddingEntity,
  ];

  return `${nameElements.join('-').replace(/\W/g, '-')}.pdf`;
}

export function getOfferType(type: OfferType | null) {
  switch (type) {
    case 'debt':
      return 'Debt';
    case 'entitlement_offer':
      return 'Entitlement Offer';
    case 'ipo':
      return 'IPO';
    case 'placement':
      return 'Placement';
    case 'pre_ipo':
      return 'Pre-IPO';
    case 'private':
      return 'Private';
    case 'share_purchase_plan':
      return 'Share Purchase Plan';
    default:
      return 'Capital Raise';
  }
}

export function getOptionListingCondition(condition: string | null) {
  switch (condition) {
    case 'will':
      return 'Listed Options';
    case 'will not':
      return 'Unlisted Options';
    default:
      return 'Options';
  }
}

export function getPhoneNumber(phoneNumber: string | null) {
  let parsedNumber = '';

  if (phoneNumber) {
    const elements = phoneNumber.split(';');

    if (elements.length === 2) {
      if (isSupportedCountry(elements[0]) && elements[1].match(/^[0-9]+$/)) {
        const result = parsePhoneNumber(elements[1], elements[0] as any);

        parsedNumber = result.number.toString();
      } else {
        parsedNumber = elements[1];
      }
    } else {
      parsedNumber = elements[0];
    }
  }

  return parsedNumber;
}

export function getRedirectURL(pathname?: string | null) {
  if (process.env.NODE_ENV === 'development') {
    return `${window.location.protocol}//${window.location.hostname}:${
      window.location.port
    }${pathname || href.liveCapitalRaises}`;
  }

  return `${window.location.protocol}//${window.location.hostname}${
    pathname || href.liveCapitalRaises
  }`;
}

export function toTitleCase(text: string | null) {
  if (text) {
    const elements = text.split(' ');

    const results = elements.map((element) => {
      if (/[a-z]/.test(element.charAt(0))) {
        return `${element.charAt(0).toUpperCase()}${element.substring(1)}`;
      }

      return element;
    });

    return results.join(' ');
  }
  return '';
}

export function getCountryAlpha3Code(countries: ICountry[], search: string) {
  const country = countries.find(
    (c) =>
      c.alpha2Code === search ||
      c.alpha3Code === search ||
      c.name === search ||
      c.nativeName === search,
  );

  return country ? country.alpha3Code : 'Unknown';
}

// Address helpers
export function formatAddress(
  values: {
    country: string | null;
    postalCode: string | null;
    region?: string | null;
    state?: string | null;
    streetAddress: string | null;
    suburb: string | null;
  },
  countries: ICountry[],
) {
  return `${values.streetAddress}, ${values.suburb}, ${
    values.region || values.state
  }, ${values.country ? getCountryName(countries, values.country) : ''} ${
    values.postalCode
  }`;
}

export function preventSubmitOnEnter(keyEvent: React.KeyboardEvent) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

//
// Profile Status helper functions
//

// Email confirmation status
export const emailStatus = (me: Me): CheckStatus => {
  if (me.isConfirmed) {
    return 'verified';
  }
  return 'incomplete';
};

// ID check status
export const identityStatus = (me: Me): CheckStatus => {
  if (me.isVerified) {
    return 'verified';
  }
  if (me.frankieCheck || me.digitalId) {
    return 'pending';
  }
  return 'incomplete';
};

// Soph status
export const investorStatus = (me: Me): CheckStatus => {
  if (me.isSophisticated) {
    return 'verified';
  }
  if (me.isRetail) {
    return 'retail';
  }
  if (me.s708Certificate) {
    return 'pending';
  }
  return 'incomplete';
};

// Phone confirmation status
export const phoneStatus = (me: Me): CheckStatus => {
  if (me.mobileNumber && me.mobileNumberConfirmedAt) {
    return 'verified';
  }
  return 'incomplete';
};

// Overall Profile / Account setup status
export const profileStatus = (me: Me): CheckStatus => {
  if (investorStatus(me) === 'retail') {
    return 'retail';
  }

  if (
    investorStatus(me) === 'verified' &&
    identityStatus(me) === 'verified' &&
    emailStatus(me) === 'verified' &&
    phoneStatus(me) === 'verified'
  ) {
    return 'verified';
  }

  if (
    investorStatus(me) === 'incomplete' ||
    identityStatus(me) === 'incomplete' ||
    emailStatus(me) === 'incomplete' ||
    phoneStatus(me) === 'incomplete'
  ) {
    return 'incomplete';
  }

  return 'pending';
};

export function isDecimal(value: number) {
  return `${value}`.includes('.');
}

export function isEditorEmpty(value?: string | null) {
  if (value) {
    const valueJSON = Value.fromJSON(JSON.parse(value));
    const plainText = Plain.serialize(valueJSON as any).trim();
    if (plainText !== '') {
      return false;
    }
  }
  return true;
}

export function getOrganisationCodes(
  organisation: {
    id: string;
    listings: Listing[];
  } | null,
) {
  return (
    organisation?.listings.reduce((prev, curr, i) => {
      if (i === 0) {
        return `${curr.exchange.key}:${curr.key}`;
      }

      return `${prev} - ${curr.exchange.key}:${curr.key}`;
    }, '') ?? 'Unknown'
  );
}

// For user data privacy in case their account got hacked
export const hideEmail = (email: string) => {
  const name = email.substring(1, email.lastIndexOf('@'));
  const replacement = '*'.repeat(name.length);
  return email.replace(name, replacement);
};

export const hidePhone = (phoneNumber: string, showPrefix = false) => {
  if (phoneNumber.length < 6) {
    return phoneNumber;
  }
  const prefix = phoneNumber.substr(0, phoneNumber.indexOf(';') + 1);
  const remainder = phoneNumber.replace(prefix, '');
  const first6 = remainder.substring(0, 6);
  const replacement = '******';
  if (showPrefix) {
    return prefix + remainder.replace(first6, replacement);
  }
  return remainder.replace(first6, replacement);
};

export function isWeekend(): boolean {
  const now = new Date();
  const day = now.getDay();

  return [6, 0].includes(day);
}

// Used for simpler/shorter company names
export function simpleName(name: string): string {
  return name.replace('Limited', '').replace('Ltd', '');
}
