import React, { createContext, FC, useContext, useState } from 'react';
// Utils
import { FeatureOnboardingName } from '../utils-ts/types';

interface ITourContext {
  activeStep: number | null;
  clickNext: () => void;
  closeTour: () => void;
  feature: FeatureOnboardingName | null;
  isTourOpen: boolean;
  openTour: (feature: FeatureOnboardingName) => void;
}

const TourContext = createContext<ITourContext>({
  activeStep: null,
  clickNext: () => {},
  closeTour: () => {},
  isTourOpen: false,
  feature: null,
  openTour: () => {},
});

export const TourContextProvider: FC = ({ children }) => {
  const [activeStep, setActiveStep] = useState<number | null>(null);
  const [feature, setFeature] = useState<FeatureOnboardingName | null>(null);
  const [open, setOpen] = useState(false);

  const clickNext = () => {
    setActiveStep(!!activeStep ? activeStep + 1 : null);
  };

  const closeTour = () => {
    setActiveStep(null);
    setFeature(null);
    setOpen(false);
  };

  const openTour = (feature: FeatureOnboardingName) => {
    setActiveStep(1);
    setFeature(feature);
    setOpen(true);
  };

  return (
    <TourContext.Provider
      value={{
        activeStep,
        clickNext,
        closeTour,
        isTourOpen: open,
        feature,
        openTour,
      }}
    >
      {children}
    </TourContext.Provider>
  );
};

export const useTour = () => useContext(TourContext);
