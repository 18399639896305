import React, { FC, useContext } from 'react';
// MUI Core
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
// MUI Icons
import CloseIcon from '@material-ui/icons/Close';
// Contexts
import ModalContext from '../../contexts/ModalContext';
// FUI
import Button from '../../fui/v2/Button';
import Typography from '../../fui/v2/Typography';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  icon: {
    color: '#000000',
  },
  iconButton: {
    padding: 0,
  },
}));

const Modal: FC = () => {
  const { actions, close, content, hideClose, isVisible, title } =
    useContext(ModalContext);

  const classes = useStyles();

  return (
    <Dialog
      aria-describedby="slide-modal-description"
      aria-labelledby="slide-modal-title"
      disableBackdropClick
      fullWidth
      keepMounted
      maxWidth="xs"
      open={isVisible}
      TransitionComponent={Transition}
    >
      <Box p={2}>
        <Grid container spacing={2}>
          {/* Header */}
          <Grid item xs={12}>
            <Grid alignItems="flex-start" container justify="space-between">
              <Grid item xs>
                <Typography variant="button">{title}</Typography>
              </Grid>

              {hideClose !== true && (
                <Grid item>
                  <IconButton className={classes.iconButton} onClick={close}>
                    <CloseIcon className={classes.icon} fontSize="small" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Content */}
          {content && (
            <Grid item xs={12}>
              {content}
            </Grid>
          )}

          {/* CTA */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {actions.map((act) => (
                <Grid item key={act.title} xs={6}>
                  <Button
                    color={act.color}
                    fullWidth
                    onClick={() => {
                      close();

                      if (act.onClick) {
                        act.onClick();
                      }
                    }}
                    size="small"
                    variant="contained"
                  >
                    {act.title}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default Modal;
