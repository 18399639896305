import { createContext, useContext, useState } from 'react';
// Modules
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';

type Variant = 'error' | 'success';

interface Message {
  id: string;
  message: string;
  variant: Variant;
}

interface ISnackbarContext {
  showSnackbar(message: string, variant: Variant): void;
}

const SnackbarContext = createContext<ISnackbarContext>({
  showSnackbar() {},
});

export const SnackbarProvider: React.ComponentType = ({ children }) => {
  const [messages, setMessages] = useState<Message[]>([]);

  function closeSnackbar(id: string) {
    setMessages((prevValue) => prevValue.filter((pv) => pv.id !== id));
  }

  function showSnackbar(message: string, variant: Variant) {
    const id = uuid();

    setMessages((prevValue) => {
      if (prevValue.length < 3) {
        return [...prevValue, { id, message, variant }];
      }

      return [...prevValue.slice(1), { id, message, variant }];
    });

    setTimeout(() => {
      setMessages((prevValue) => prevValue.filter((pv) => pv.id !== id));
    }, 6000);
  }

  return (
    <SnackbarContext.Provider
      value={{
        showSnackbar,
      }}
    >
      {children}
      <div
        className={clsx(
          'fixed bottom-0 left-1/2 p-4 space-y-4 w-full max-w-sm -translate-x-1/2',
          messages.length === 0 && 'hidden',
        )}
      >
        {messages.map((m) => (
          <div
            key={m.id}
            className={clsx(
              'p-4 rounded-md shadow-lg',
              m.variant === 'error' && 'bg-critical',
              m.variant === 'success' && 'bg-fresh',
            )}
          >
            <div className="flex items-center">
              <div className="shrink-0">
                {m.variant === 'error' && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
                {m.variant === 'success' && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-white">{m.message}</p>
              </div>
              <div className="pl-3 ml-auto">
                <div className="-m-1.5">
                  <button
                    className={clsx(
                      'inline-flex p-1.5 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2',
                      m.variant === 'error' &&
                        'bg-critical hover:bg-critical-text focus:ring-critical-text focus:ring-offset-critical',
                      m.variant === 'success' &&
                        'bg-fresh hover:bg-pine focus:ring-pine focus:ring-offset-fresh',
                    )}
                    onClick={() => closeSnackbar(m.id)}
                    type="button"
                  >
                    <span className="sr-only">Dismiss</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </SnackbarContext.Provider>
  );
};

export function useSnackbar() {
  return useContext(SnackbarContext);
}

export default SnackbarContext;
