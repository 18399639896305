import sortBy from 'lodash/sortBy';
import React, { FC, useEffect, useState } from 'react';
// Contexts
import CountryContext, { ICountryContext } from './CountryContext';
import ModalContext, { IModalContext } from './ModalContext';
import { SnackbarProvider } from './SnackbarContext';
import { TooltipContextProvider } from './TooltipContext';
import { TourContextProvider } from './TourContext';
import { WebViewProvider } from './WebViewContext';

const ContextProvider: FC = ({ children }) => {
  const [countryContext, setCountryContext] = useState<ICountryContext>({
    countries: [],
    loading: true,
  });

  const [modalContext, setModalContext] = useState<IModalContext>({
    actions: [],
    close: closeModal,
    content: undefined,
    hideClose: false,
    isVisible: false,
    open: ({ actions, content, hideClose, onClose, title }) => {
      setModalContext((prevValue) => ({
        ...prevValue,
        actions: actions || [],
        // If provided onClose will override default behaviour.
        close: onClose
          ? () => {
              closeModal();
              onClose();
            }
          : closeModal,
        content,
        hideClose: hideClose || false,
        isVisible: true,
        title,
      }));
    },
    title: '',
  });

  useEffect(() => {
    fetch('/countries.json')
      .then((res) => res.json())
      .then((data) =>
        setCountryContext((prevValue) => ({
          ...prevValue,
          countries: sortBy(data, 'name'),
          loading: false,
        })),
      )
      .catch(() =>
        setCountryContext((prevValue) => ({
          ...prevValue,
          countries: [],
          loading: false,
        })),
      );
  }, []);

  function closeModal() {
    setModalContext((prevValue) => ({
      ...prevValue,
      isVisible: false,
    }));
  }

  return (
    <WebViewProvider>
      <CountryContext.Provider value={countryContext}>
        <ModalContext.Provider value={modalContext}>
          <SnackbarProvider>
            <TooltipContextProvider>
              <TourContextProvider>{children}</TourContextProvider>
            </TooltipContextProvider>
          </SnackbarProvider>
        </ModalContext.Provider>
      </CountryContext.Provider>
    </WebViewProvider>
  );
};

export default ContextProvider;
