// native smooth scrolling for Chrome, Firefox & Opera
export function scrollToElem(element: HTMLElement, top: number | null = null) {
  if (supportsNativeSmoothScroll()) {
    nativeSmoothScrollTo(top || 0);
  } else if (element) {
    smoothScrollTo(top || element.offsetTop, 400);
  }
}

export function scrollToTop(preferSmooth?: boolean) {
  if (preferSmooth && supportsNativeSmoothScroll()) {
    nativeSmoothScrollTo(0);
  } else {
    smoothScrollTo(0, 600);
  }
}

// Native use
function nativeSmoothScrollTo(top: number) {
  window.scroll({
    behavior: 'smooth',
    top,
  });
}

// polyfilled smooth scrolling for IE, Edge & Safari
function smoothScrollTo(to: number, duration: number) {
  const element = document.scrollingElement || document.documentElement;
  const start = element.scrollTop;
  const change = to - start;
  const startDate = +new Date();

  // t = current time
  // b = start value
  // c = change in value
  // d = duration
  const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t -= 1;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const animateScroll = () => {
    const currentDate = +new Date();
    const currentTime = currentDate - startDate;
    element.scrollTop = easeInOutQuad(currentTime, start, change, duration);
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      element.scrollTop = to;
    }
  };
  animateScroll();
}

// detect support for the behavior property in ScrollOptions
function supportsNativeSmoothScroll(): boolean {
  if (typeof window !== 'undefined') {
    return 'scrollBehavior' in document.documentElement.style;
  }
  return false;
}
