export type BiddingEntityBasicInformationType =
  | 'create'
  | 'entity-type'
  | 'legal-name'
  | 'nickname'
  | 'address';

export enum BiddingEntityStatus {
  completed = 'completed',
  draft = 'draft',
}

// TODO: Check if any existing places that use BiddingEntityType need to be refactored
export enum BiddingEntityType {
  company = 'company',
  individual = 'individual',
  partnership = 'partnership',
  smsf = 'smsf',
  trust = 'trust',
}

export type BidStatus =
  | 'done'
  | 'pending allocation'
  | 'pending confirmation'
  | 'pending signature'
  | 'rejected'
  | 'withdrawn';

export type BidDetailStatus = BidStatus | 'pending bid increase';

export type BidHoldingIntention =
  | '1 month (28 days)'
  | '1 - 6 months'
  | 'More than 6 months'
  | 'Prefer not to commit'
  | 'Skipped';

export type BreadcrumbLink = {
  route: string;
  title: string;
};

export type CheckStatus = 'incomplete' | 'pending' | 'verified' | 'retail';

export type CreateBiddingEntityOwnersStage = 'type' | 'detail';

export type CreateBiddingEntityOwnersType =
  | 'partnership'
  | 'trustees'
  | 'directors'
  | 'individual'
  | 'benificial';

export type DebtCouponFrequency =
  | 'annual'
  | 'quarterly'
  | 'semi-annual'
  | 'monthly';

export type DebtCouponType = 'fixed' | 'floating' | 'payable at maturity';

export type FeatureOnboardingName =
  | 'eo_education_modal'
  | 'eo_education_tutorial'
  | 'indices'
  | 'newsfeed'
  | 'notification_v2_sw'
  | 'or_dialog'
  | 'or_transactions'
  | 'sector';

export enum Flag {
  eft_payment_receipt = 'eft_payment_receipt',
  ipo_pathfinder = 'ipo_pathfinder',
  sw_offer_restructure = 'sw_offer_restructure',
  sw_refactor_offer_page = 'sw_refactor_offer_page',
  experienced_investor = 'experienced_investor',
  notification_preferences = 'notification_preferences',
  rttv_v2 = 'rttv_v2',
}

export enum FrankieResultState {
  UNCHECKED = 'UNCHECKED',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  CHECKING = 'CHECKING',
  UNOPROCESSABLE = 'UNOPROCESSABLE',
  NO_MATCH = 'NO_MATCH',
  CHECKED_PARTIAL_SUCCESS = 'CHECKED_PARTIAL_SUCCESS',
  CHECKED_SUCCESS_WITH_NOTES = 'CHECKED_SUCCESS_WITH_NOTES',
  CHECKED_SUCCESS_CLEAR = 'CHECKED_SUCCESS_CLEAR',
  CHECKED_FAILED = 'CHECKED_FAILED',
}

export type IdentityVerificationType = 'onboarding' | 'invite' | 'edit';

export type InvestorType = 'professional' | 'retail' | 'sophisticated';

export type InvestorVerificationStatus =
  | 'expired'
  | 'expiring'
  | 'none'
  | 'pending'
  | 'verified';

export type MultiStepFormType = 'onboarding' | 'profile' | 'edit';

export type NotificationPreference =
  | 'daily_deal_email'
  | 'individual_deal_email'
  | 'individual_deal_notification'
  | 'monthly_newsletter_email'
  | 'all_suppressed';

export type OfferState = 'closed' | 'last' | 'live' | 'pending';

export type OfferType =
  | 'debt'
  | 'entitlement_offer'
  | 'ipo'
  | 'placement'
  | 'pre_ipo'
  | 'private'
  | 'share_purchase_plan'
  | 'unknown';

export type PermissionType = 'create' | 'delete' | 'update' | 'view';

export type SettlementType = 'dvp' | 'manual';

export type TimeseriesChartType = 'area' | 'candlestick';

export type TimeseriesVariant = 'intraday' | '3_month' | '1_year' | '5_year';

export type TokenFlag = 'TTV_CR_FLAG';

export type VerifyEntityStageType = 'welcome' | 'IV' | 'done';

/**
 * Legacy support for reading from backend only
 * All the new investor type should follow the above InvestorType
 */
export type InvestorTypeOld =
  | 'experienced'
  | 'other'
  | 'professional'
  | 'retail'
  | 'sophisticated';
