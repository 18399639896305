import React from 'react';
// Modules
import * as AbsintheSocket from '@absinthe/socket';
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link';
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import {
  getMainDefinition,
  relayStylePagination,
} from '@apollo/client/utilities';
import { createLink } from 'apollo-absinthe-upload-link';
import cookieParser from 'cookie';
import merge from 'deepmerge';
import { Socket as PhoenixSocket } from 'phoenix';
// Utils
import { COOKIE } from './constants';

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

function createApolloClient(cookie?: string) {
  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            articleList: relayStylePagination(['options']),
            feedItemList: relayStylePagination(['options']),
            organisationList: relayStylePagination(['options']),
            pastRaiseOfferList: relayStylePagination(['options']),
            pastTransactions: relayStylePagination(['options']),
            tradingHaltOffers: relayStylePagination(['options']),
          },
        },
        OnboardingStatus: {
          keyFields: ['key'],
        },
      },
    }),
    link: createApolloLink(cookie),
    ssrMode: typeof window === 'undefined',
  });
}

function createApolloLink(cookie: string = '') {
  const httpLink = createLink({
    credentials: 'include',
    headers: {
      cookie: Object.entries(cookieParser.parse(cookie))
        .filter(([key, _value]) => key.startsWith('_fresh'))
        .map(([key, value]) => `${key}=${value}`)
        .join('; '),
    },
    uri: `${process.env.NEXT_PUBLIC_API_URL}/graphql?source=sw`,
  });

  if (typeof document !== 'undefined') {
    const token = cookieParser.parse(document.cookie)[COOKIE.SOCKET_TOKEN];

    const socketLink = createAbsintheSocketLink(
      AbsintheSocket.create(
        new PhoenixSocket(`${process.env.NEXT_PUBLIC_SOCKET_URL}/socket`, {
          params: {
            token,
          },
        }),
      ),
    );

    return split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      socketLink,
      httpLink,
    );
  }

  return httpLink;
}

// The function makes sure the onboardingStatus/identityChecks array doesn't get appended to
// each time server cache is passed to client side.

const mergeServerOnly = (_clientStatus: any[], serverStatus: any[]) =>
  serverStatus;

const serverMergeKeys = ['onboardingStatus', 'identityChecks', 'attachments'];

const mergeOptions = {
  customMerge: (key: string) => {
    if (serverMergeKeys.includes(key)) {
      return mergeServerOnly;
    }
  },
};
interface Options {
  cookie?: string;
  initialState?: NormalizedCacheObject;
}

export function initApollo(opts?: Options) {
  const _apolloClient = apolloClient ?? createApolloClient(opts?.cookie);

  if (opts?.initialState) {
    const existingCache = _apolloClient.extract();

    // Merge client and server cache
    // If an element at the same key is present for both client and server,
    // the value from server will appear in the result.
    const data = merge(existingCache, opts.initialState, mergeOptions);

    _apolloClient.cache.restore(data);
  }

  if (typeof window === 'undefined') return _apolloClient;

  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState?: NormalizedCacheObject) {
  return React.useMemo(() => initApollo({ initialState }), [initialState]);
}
