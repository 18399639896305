import * as Sentry from '@sentry/nextjs';
import { Entry, EntryCollection, RichTextContent } from 'contentful';
import { asPath } from '../pages';
import { Tombstone } from '../tombstones';
import { Link, mapEntryToPost, Post, getClient } from './base';

export interface DynamicLandingPage {
  slug: string;
  title: string;
}

export interface LandingPageQuery {
  excludeSlug?: string;
  excludePageType?: string;
  limit?: number;
  query?: string;
  pageTypeSlug?: LandingPageTypeSlug;
  skip?: number;
  slug?: string;
}

export interface LandingPage {
  pageType: LandingPageType;
  slug: string;
  title: string;
  subtitle: string;
  headerContent: RichTextContent | null;
  headerCTA: Link | null;
  headerImage: string | null;
  createdAt: string;
  updatedAt: string;
  contentSections: LandingPageSectionType[] | null;
}

export interface LandingPageContentSection {
  type: 'content';
  title: string;
  titleSize: 'large' | 'small';
  layout: LandingPageContentSectionLayout;
  subSections: LandingPageSubsection[];
}

export interface LandingPageSubsection {
  subtitle: string;
  content: RichTextContent;
  link: Link | null;
}

export interface LandingPageStatsSubsection {
  title: string;
  statToShow: LandingPageStatType;
}

export interface LandingPageRelatedReadings {
  type: 'blogs';
  title: string;
  posts: Post[];
}

export interface LandingPageNextPrevSection {
  type: 'next-prev';
  title: string;
  prevLink: Link;
  nextLink: Link;
}

export interface LandingPageContactSection {
  type: 'contact';
  title: string;
}

export interface LandingPageStatsSection {
  type: 'stats';
  title: string;
  sectors: string[];
  stats: LandingPageStatsSubsection[];
}

export interface LandingPageTeaserSection {
  type: 'teaser';
  title: string;
  layout: TeaserLayoutType;
}

export interface LandingPageTombstonesSection {
  type: 'tombstones';
  title: string;
  tombstones: Tombstone[];
}

export interface LandingPageSectorInfoSection {
  type: 'sector';
  title: string;
  content: RichTextContent;
}

export type LandingPageType = 'Use case' | 'Sector breakdown';

export type LandingPageTypeSlug = 'use-cases' | 'sector-breakdowns';

export type TeaserLayoutType = 'horizontal' | 'vertical';

export type LandingPageContentSectionLayout = '2 column' | '3 column';

export type LandingPageStatType =
  | 'Active investors'
  | 'Bids processed'
  | 'Raises supported'
  | 'Average bid size'
  | 'Average deal size'
  | 'Number of bids';

export type LandingPageSectionType =
  | LandingPageContentSection
  | LandingPageRelatedReadings
  | LandingPageNextPrevSection
  | LandingPageContactSection
  | LandingPageStatsSection
  | LandingPageTeaserSection
  | LandingPageTombstonesSection
  | LandingPageSectorInfoSection
  | null;

/// ////////////////////////////////////////////////////////////////////////////
// Helper                                                                    //
/// ////////////////////////////////////////////////////////////////////////////

export function landingPageSlug(pageType: LandingPageType) {
  switch (pageType) {
    case 'Use case':
      return 'use-cases';
    case 'Sector breakdown':
      return 'sector-breakdowns';
    default:
      return 'unknown';
  }
}

export function landingPageTypeFromSlug(
  pageTypeSlug: LandingPageTypeSlug | undefined,
) {
  switch (pageTypeSlug) {
    case 'use-cases':
      return 'Use case';
    case 'sector-breakdowns':
      return 'Sector breakdown';
    default:
      return undefined;
  }
}

function mapEntryToLandingPage(entry: Entry<any>): LandingPage {
  const { headerCta, headerImage } = entry.fields;

  const page = {
    pageType: entry.fields.pageType,
    slug: entry.fields.slug,
    title: entry.fields.title,
    subtitle: entry.fields.subtitle,
    headerContent: entry.fields.headerContent || null,
    headerCTA: headerCta
      ? { text: headerCta.fields.text, url: headerCta.fields.url }
      : null,
    headerImage: headerImage ? headerImage.fields.file.url : null,
    createdAt: entry.sys.createdAt,
    updatedAt: entry.sys.updatedAt,
    contentSections: entry.fields.contentSections.map(
      mapEntryToLandingPageContentSection,
    ),
  };

  return page;
}

function mapEntryToLandingPageContentSection(
  entry: Entry<any>,
): LandingPageSectionType | null {
  // An un published field will return something like this
  // Nothing to say it is unpublished...
  // {
  //   "sys": {
  //     "type": "Link",
  //     "linkType": "Entry",
  //     "id": "51RZB1k6ofLmoNsVWh5RzR"
  //   }
  // }

  if (!entry.sys.contentType) {
    return null;
  }

  switch (entry.sys.contentType.sys.id) {
    case 'landingPageSection':
      return {
        type: 'content',
        title: entry.fields.title,
        titleSize: entry.fields.titleSize || 'large',
        layout: entry.fields.layout || '2 column',
        subSections: entry.fields.subsections.map(
          mapEntryToLandingPageSubsection,
        ),
      };
    case 'landingPageRelatedReadings':
      return {
        type: 'blogs',
        title: entry.fields.title,
        posts: entry.fields.posts.map(mapEntryToPost),
      };
    case 'landingPageNextPrevSection':
      return {
        type: 'next-prev',
        title: entry.fields.title,
        nextLink: getNextPrevLinkFromEntry(entry.fields.nextLink),
        prevLink: getNextPrevLinkFromEntry(entry.fields.prevLink),
      };
    case 'landingPageContactSection':
      return {
        type: 'contact',
        title: entry.fields.title,
      };
    case 'landingPageStatsSection':
      return {
        type: 'stats',
        title: entry.fields.title,
        sectors: entry.fields.sectors || [],
        stats: entry.fields.statsToShow
          ? entry.fields.statsToShow.map(mapEntryToLandingPageStatsSubsection)
          : [],
      };
    case 'landingPageTeaserSection':
      return {
        type: 'teaser',
        title: entry.fields.title,
        layout: entry.fields.layout,
      };
    case 'landingPageTombstonesSection':
      return {
        type: 'tombstones',
        title: entry.fields.title,
        tombstones: entry.fields.tombstones.map(mapEntryToTombstones),
      };
    case 'landingPageSectorInfoSection':
      return {
        type: 'sector',
        title: entry.fields.title,
        content: entry.fields.content,
      };
    default:
      return null;
  }
}

function mapEntryToLandingPageSubsection(
  entry: Entry<any>,
): LandingPageSubsection {
  const { content, link, subtitle } = entry.fields;
  return {
    subtitle,
    content,
    link: link ? { text: link.fields.text, url: link.fields.url } : null,
  };
}

function mapEntryToLandingPageStatsSubsection(
  entry: Entry<any>,
): LandingPageStatsSubsection {
  return {
    title: entry.fields.title,
    statToShow: entry.fields.statToShow,
  };
}

function mapEntryToTombstones(entry: Entry<any>): Tombstone {
  return {
    date: entry.fields.date,
    logo: entry.fields.logo.fields.file.url,
    listingKey: entry.fields.listingKey,
    offerPrice: entry.fields.offerPrice,
    name: entry.fields.name,
  };
}

function getNextPrevLinkFromEntry(entry: Entry<any>): Link {
  const { fields, sys } = entry;
  switch (sys.contentType.sys.id as 'genericLandingPage' | 'link') {
    case 'genericLandingPage':
      return {
        text: fields.title,
        url: asPath.landingPage(fields.pageType, fields.slug),
      };
    default:
      return {
        text: fields.text,
        url: fields.url,
      };
  }
}

/// ////////////////////////////////////////////////////////////////////////////
// API                                                                       //
/// ////////////////////////////////////////////////////////////////////////////

export async function getLandingPages({
  excludeSlug,
  excludePageType,
  limit,
  query,
  pageTypeSlug,
  skip,
  slug,
}: LandingPageQuery) {
  try {
    const entries: EntryCollection<any> = await getClient().getEntries({
      content_type: 'genericLandingPage',
      include: 4,
      limit,
      order: '-sys.updatedAt',
      query,
      skip,
      'fields.slug': slug,
      'fields.slug[nin]': excludeSlug,
      'fields.pageType': landingPageTypeFromSlug(pageTypeSlug),
      'fields.pageType[nin]': excludePageType,
    });

    const pages: LandingPage[] = entries.items.map(mapEntryToLandingPage);

    return {
      pages,
      total: entries.total,
    };
  } catch (error) {
    Sentry.captureException(error);

    return {
      pages: [],
      total: 0,
    };
  }
}
