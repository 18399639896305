import React from 'react';
// Modules
import clsx from 'clsx';

export interface Props {
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
  className?: string;
  color?:
    | 'inherit'
    | 'initial'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
  component?: React.ElementType<any>;
  display?: 'block' | 'initial' | 'inline';
  noWrap?: boolean;
  style?: React.CSSProperties;
  /**
   * display-xl - 48px, 500, serif
   *
   * display-lg - 32px, 500, serif
   *
   * display-md - 28px, 500
   *
   * heading-lg - 24px, 500
   *
   * heading-sm - 20px, 600
   *
   * button - 16px, 500
   *
   * button-sm - 14px, 500
   *
   * body-bold-lg - 16px, 500
   *
   * body-bold-sm - 14px, 500
   *
   * body-bold-xs - 12px, 500
   *
   * badge - 14px, 500, 0.4 letter spacing
   *
   * body-xl - 20px, 400
   *
   * body-lg - 16px, 400
   *
   * body-sm - 14px, 400 - default
   *
   * body-xs - 12px, 400
   */
  variant?:
    | 'display-xl'
    | 'display-lg'
    | 'display-md'
    | 'heading-lg'
    | 'heading-sm'
    | 'button'
    | 'button-sm'
    | 'body-bold-xl'
    | 'body-bold-lg'
    | 'body-bold-sm'
    | 'body-bold-xs'
    | 'badge'
    | 'body-xl'
    | 'body-lg'
    | 'body-sm'
    | 'body-xs';
}

const Typography: React.FC<Props> = ({
  align,
  children,
  className,
  color,
  component = 'p',
  display,
  noWrap,
  style,
  variant = 'body-sm',
}) => {
  return React.createElement(
    component,
    {
      className: clsx(
        align === 'center' && 'text-center',
        align === 'justify' && 'text-justify',
        align === 'left' && 'text-left',
        align === 'right' && 'text-right',
        color === 'error' && 'text-critical',
        color === 'primary' && 'text-premium',
        color === 'secondary' && 'text-fresh',
        color === 'textPrimary' && 'text-jet',
        color === 'textSecondary' && 'text-ash',
        display === 'block' && 'block',
        display === 'inline' && 'inline',
        noWrap && 'truncate',
        variant === 'display-xl' &&
          'font-serif text-[32px] font-medium sm:text-5xl',
        variant === 'display-lg' &&
          'font-serif text-2xl font-medium sm:text-[32px]',
        variant === 'display-md' &&
          'font-sans text-2xl font-medium sm:text-[28px]',
        variant === 'heading-lg' && 'font-sans text-xl font-medium sm:text-2xl',
        variant === 'heading-sm' && 'font-sans text-lg font-bold sm:text-xl',
        variant === 'body-xl' && 'font-sans text-base font-normal sm:text-xl',
        variant === 'body-bold-xl' &&
          'font-sans text-base font-medium sm:text-xl',
        variant === 'body-bold-lg' && 'font-sans text-base font-medium',
        variant === 'body-lg' && 'font-sans text-base font-normal',
        variant === 'button' && 'font-sans text-base font-medium',
        variant === 'badge' && 'font-sans text-sm font-medium tracking-wide',
        variant === 'body-bold-sm' && 'font-sans text-sm font-medium',
        variant === 'body-sm' && 'font-sans text-sm font-normal',
        variant === 'button-sm' && 'font-sans text-sm font-medium',
        variant === 'body-xs' && 'font-sans text-xs font-normal',
        variant === 'body-bold-xs' && 'font-sans text-xs font-medium',
        className,
      ),
      style,
    },
    children,
  );
};

export default Typography;
