import React, { FC } from 'react';
// Modules
import { CorporateContactJsonLd, DefaultSeo } from 'next-seo';
import { JsonLd } from './JsonLd';
// Utils
import { OFFICE_NUMBER } from '../../utils-ts/constants';

const DefaultSEO: FC = () => {
  const description =
    'We provide comprehensive access to listed capital raises for institutional, family office and high net worth investors.';

  const siteNavLdData = {
    '@context': 'http://schema.org',
    '@type': 'ItemList',
    itemListElement: [
      {
        '@type': 'SiteNavigationElement',
        position: 1,
        name: 'Live Capital Raises',
        description: 'ASX listed companies currently raising capital',
        url: 'https://www.freshequities.com/discover',
      },
      {
        '@type': 'SiteNavigationElement',
        position: 2,
        name: 'ASX Listed companies',
        description:
          'Search and filter all companies listed on the ASX, ordered by most recent capital raising.',
        url: 'https://www.freshequities.com/ASX',
      },
      {
        '@type': 'SiteNavigationElement',
        position: 3,
        name: 'Resource Centre',
        description:
          'A collection of articles about public company capital raises and information about how Fresh Equities works.',
        url: 'https://www.freshequities.com/resource-centre',
      },
      {
        '@type': 'SiteNavigationElement',
        position: 4,
        name: 'FAQs',
        description: 'Fresh Equities frequently asked questions',
        url: 'https://www.freshequities.com/faq',
      },
      // {
      //   '@type': 'SiteNavigationElement',
      //   position: 5,
      //   name: 'About Us',
      //   description: 'Our mission, our team, media appearances, and awards.',
      //   url: 'https://www.freshequities.com/about',
      // },
    ],
  };

  return (
    <>
      <DefaultSeo
        additionalMetaTags={[
          {
            content: 'ck69bzh0az3romahfdc9bgsrr47ypc',
            name: 'facebook-domain-verification',
          },
          {
            content: 'ASX,Capital Raise,Fresh Equities,Placement',
            name: 'keywords',
          },
          {
            content: 'minimum-scale=1, initial-scale=1, width=device-width',
            name: 'viewport',
          },
        ]}
        description={description}
        openGraph={{
          description,
          images: [
            {
              url: 'https://www.freshequities.com/images/assets/og-square.png',
              width: 1024,
              height: 1024,
              alt: 'Fresh Equities Social Sharing Image',
            },
            {
              url: 'https://www.freshequities.com/images/assets/og-image.png',
              width: 1200,
              height: 630,
              alt: 'Fresh Equities Alternative Social Sharing Image',
            },
          ],
          locale: 'en_AU',
          title: 'Fresh Equities',
          site_name: 'Fresh Equities',
          type: 'website',
          url: 'https://www.freshequities.com',
        }}
        title="Fresh Equities"
        twitter={{
          handle: '@FreshEquities',
          site: '@FreshEquities',
          cardType: 'summary',
        }}
      />
      <CorporateContactJsonLd
        url="https://www.freshequities.com"
        logo="https://www.freshequities.com/images/assets/logo-horizontal-green.svg"
        contactPoint={[
          {
            telephone: OFFICE_NUMBER,
            contactType: 'customer service',
            areaServed: 'Australia',
            availableLanguage: ['English'],
          },
        ]}
      />
      <JsonLd data={siteNavLdData} keyId="site nav ld+json data" />
    </>
  );
};

export default DefaultSEO;
